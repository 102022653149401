import { createRouter, createWebHistory } from "vue-router";
import StockTakeSheetsView from "@/views/StockTakeSheetsView.vue";
import StockTakeSheetProductsView from "../views/StockTakeSheetProductsView.vue";
import SettingsView from "@/views/SettingsView.vue";

const routes = [
  {
    path: "/",
    name: "Sheets",
    component: StockTakeSheetsView,
  },
  {
    path: "/products/:sheetId?",
    name: "Products",
    component: StockTakeSheetProductsView,
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
