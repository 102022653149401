import { createApp, watch, computed } from "vue";
// import { createApp, watch, computed } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./style.css";

import { createPinia } from "pinia";

const pinia = createPinia();

createApp(App).use(router).use(pinia).mount("#app");

watch(
  pinia.state,
  (state) => {
    // console.log("state: ", state.stockTakeStore);
    
    const stockTakeSheetsArray = computed(() => state.stockTakeStore.stockTakeSheets);
    // console.log("stockTakeSheetsArray: ", stockTakeSheetsArray);
    const productsArray = computed(() => state.stockTakeStore.products);
    // console.log("productsArray: ", productsArray);

    localStorage.setItem("stockTakeSheets", JSON.stringify(stockTakeSheetsArray.value));
    localStorage.setItem("products", JSON.stringify(productsArray.value));
    localStorage.setItem("selectedCamera", (state.stockTakeStore.selectedCamera));
  },
  {
    deep: true,
  }
);