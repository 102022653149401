import { defineStore } from "pinia";
import axios from "axios";


export const useStockTakeStore = defineStore("stockTakeStore", {
  id: "stockTakeStore",
  state: () => ({
    stockTakeSheets: [],
    products: [],
    dataToSend: {},
    selectedCamera: "",
  }),
  getters: {
    hasSavedData: (state) =>
      state.stockTakeSheets.length > 0 || state.products.length > 0,
    apiUrl: () => process.env.VUE_APP_ADMIN_URL,
  },
  actions: {
    async fetchStockTakeSheets(userId) {
      console.log("Fetching stock take sheets for user ID:", userId);

      //Remove existing stock take sheets and products
      localStorage.removeItem("stockTakeSheets");
      localStorage.removeItem("products");

      try {
        const response = await axios.get(
          this.apiUrl + "api/v1/get/stockTakeSheets/" + userId
        );
        this.stockTakeSheets = response.data.sheets;

        // console.log("Fetched stock take sheets:", this.stockTakeSheets);

        this.products = [];
        for (const sheet of this.stockTakeSheets) {
          console.log("Sheet: ", sheet);
          await this.fetchProducts(sheet.id);
        }
      } catch (error) {
        console.error("Error fetching stock take sheets:", error);
        // Handle errors appropriately
      }
      console.log("Done fetching stock take sheets and products");

      localStorage.setItem("stockTakeSheets", JSON.stringify(this.stockTakeSheets));
      localStorage.setItem("products", JSON.stringify(this.products));
    },
    async fetchProducts(sheetId) {
      try {
        console.log(sheetId);
        const response = await axios.get(
          this.apiUrl + "api/v1/get/stockTakeProducts/" + sheetId
        );
        const fetchedProducts = response.data.products;
        this.products = this.products.concat(fetchedProducts);
        // console.log("Fetched products for sheet:", sheetId, fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
        // Handle errors appropriately
      }
    },

    //This method synchronizes the stockTakeStore back to the database
    async syncData(selectedSheets) {
      try {
        console.log("start sync");

        this.dataToSend = {
          stockTakeSheets: selectedSheets || this.stockTakeSheets,
          products: this.products,
        };

        console.log('dataToSend', this.dataToSend);

        const response = await axios.post(
          this.apiUrl + "api/v1/update/stockTakeData",
          this.dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);

        const userId = localStorage.getItem("user");
        this.fetchStockTakeSheets(userId);
        
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },

    updateStockTakeProduct(productId, newStatus, newQty) {
      const productIndex = this.products.findIndex(
        (product) => product.id === productId
      );

      if (productIndex !== -1) {
        this.products[productIndex].status = newStatus;

        if (this.products[productIndex].qty === "Nothing") {
          this.products[productIndex].qty = Number(newQty);
        } else {
          this.products[productIndex].qty =
            Number(this.products[productIndex].qty) + Number(newQty);
        }
      } else {
        console.warn("Stock take product not found:", productId);
      }
    },

    updateStockTakeSheetStatus(sheetId, newStatus) {
      const sheetIndex = this.stockTakeSheets.findIndex(
        (sheet) => sheet.id === Number(sheetId)
      );

      if (sheetIndex !== -1) {
        this.stockTakeSheets[sheetIndex].status = newStatus;
      } else {
        console.warn("Stock take sheet not found:", sheetId);
      }
    },

    setSelectedCamera(camera) {
      this.selectedCamera = camera;
    },
  },
});
