<template>
    <div class="p-4 text-left">
        <!-- Camera selection -->
        <!-- <select id="cameraSelection" class="w-full mb-4 border border-gray-400 rounded p-2"></select> -->

        

        <!-- Display default camera and button to change camera -->
        <div class="font-bold text-xs text-left">Default Camera</div>
        <div id="defaultCameraLabel" class="text-xs text-left"></div>
        <button id="changeCameraBtn" class="p-2 rounded text-white bg-blue-500 mb-4 text-xs text-right" @click="toggleModal()">Change Camera</button>

        <!-- Modal for selecting camera -->
        <div id="cameraModal" class="modal hidden bg-black/60 absolute z-100 w-screen h-screen top-0 left-0">
        <div class="modal-content flex flex-col relative border transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 bg-white h-64 p-4 rounded">
            <span class="close absolute right-4 top-4 text-2xl" @click="toggleModal()">&times;</span>
            <h2 class="block mt-8 font-bold">Select Camera</h2>
            <ul id="cameraList" class="block"></ul> <!-- Camera options will be populated here -->
        </div>
        </div>

        <textarea id="cameraLog" class="w-full p-2 hidden" rows="40"></textarea>   

    </div>
    
</template>

<script setup>

import { useStockTakeStore } from "@/stores/stockTakeStore";

const stockTakeStore = useStockTakeStore();

//   async function getBestCamera() {
//     const devices = await navigator.mediaDevices.enumerateDevices();
//     const videoInputs = devices.filter(device => device.kind === 'videoinput');

//     let environmentCameras = videoInputs; // Assume all cameras are potential candidates

//     // If possible, use device label to further refine selection
//     // Note: Labels might not be available due to privacy reasons until after user grants permission
//     const rearCamera = videoInputs.find(device => device.label.toLowerCase().includes('back') || device.label.toLowerCase().includes('rear'));
//     if (rearCamera) {
//         environmentCameras = [rearCamera]; // Prioritize known rear-facing camera
//     }

//     // Placeholder for potentially more sophisticated selection logic
//     // For now, simply return the first identified environment-facing camera's deviceId
//     return environmentCameras.length > 0 ? environmentCameras[0].deviceId : null;
// }


// async function getCameraDevices() {
//   try {
//     const devices = await navigator.mediaDevices.enumerateDevices();
//     cameraDevices.value = devices.filter(device => device.kind === 'videoinput' );

//     console.log("Camera devices:", cameraDevices.value);
    
//     return cameraDevices.value;
//   } catch (error) {
//     console.error("Error accessing camera devices:", error);
//     return []; // Return empty array on error
//   }
// }

// async function populateCameraSelection() {
//     const devices = await navigator.mediaDevices.enumerateDevices();
//     const videoInputDevices = devices.filter(device => device.kind === 'videoinput');

//     console.log("Devices", devices);

//     document.getElementById("cameraLog").value = JSON.stringify(devices);

//     const cameraSelect = document.getElementById('cameraSelection');
//     cameraSelect.innerHTML = ''; // Clear existing options

//     videoInputDevices.forEach((device, index) => {
//         const option = document.createElement('option');
//         option.value = device.deviceId;
//         // Use the device label if available, or provide a generic label
//         option.text = device.label || `Camera ${index + 1}`;
//         cameraSelect.appendChild(option);
//     });

//     // Add an event listener to handle camera selection changes
//     cameraSelect.onchange = async () => {
//         const deviceId = cameraSelect.value;
//         if (deviceId) {
//             await startVideoStream(deviceId);
//         }
//     };
// }

// async function startVideoStream(deviceId) {
//     const video = document.getElementById('video');
//     try {
//         const stream = await navigator.mediaDevices.getUserMedia({
//             video: { deviceId: { exact: deviceId } }
//         });
//         video.srcObject = stream;
//         video.play();
//     } catch (error) {
//         console.error('Error accessing the camera with deviceId', deviceId, error);
//     }
// }

// document.addEventListener('DOMContentLoaded', () => {
//     populateCameraSelection();
// });

// navigator.mediaDevices.addEventListener('devicechange', populateCameraSelection);

let selectedCameraId = null;

async function enumerateCameras() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoInputDevices = devices.filter(device => device.kind === 'videoinput');

    let defaultCamera = null;

    if (stockTakeStore.selectedCamera === "" || stockTakeStore.selectedCamera === undefined) {
      console.log("No camera selected");
      defaultCamera = videoInputDevices[0];
    } else {
      console.log("Camera selected:", stockTakeStore.selectedCamera);
      defaultCamera = stockTakeStore.selectedCamera;
    }

    // Assuming the first video input device is the default
    for (const device of videoInputDevices) {
        const labelLower = device.label.toLowerCase();
        if (labelLower.includes('back') || labelLower.includes('rear') || labelLower.includes('environment')) {
            defaultCamera = device; // Use the first identified environment camera as default
            break;
        }
    }

    // Update UI with default camera
    document.getElementById('defaultCameraLabel').textContent = `Default Camera: ${defaultCamera.label || 'Camera'}`;

    // Populate modal with available cameras
    const cameraList = document.getElementById('cameraList');
    cameraList.innerHTML = ''; // Clear existing list
    videoInputDevices.forEach(device => {
        const listItem = document.createElement('li');
        listItem.textContent = device.label || 'Unnamed Camera';
        listItem.onclick = () => { selectCamera(device.deviceId); }; // Add click handler to select camera
        cameraList.appendChild(listItem);
    });
}

// Function to handle camera selection
async function selectCamera(deviceId) {
    // Similar to your existing startVideoStream function, using the selected deviceId
    console.log("Camera selected:", deviceId); // Placeholder - implement camera switching logic here

    // let modal = document.getElementById('cameraModal');

    selectedCameraId = deviceId;

    console.log("Selected Camera Id", selectedCameraId);
    stockTakeStore.setSelectedCamera(selectedCameraId);
    console.log("Stock Take Store Camera", stockTakeStore.selectedCamera);
    // Close modal after selection
    toggleModal();
}

const toggleModal = async () => {

    console.log("I'm the modal");

    let modal = document.getElementById('cameraModal');

    console.log("Modal", modal)

    const permissions = await navigator.permissions.query({ name: 'camera' });

    console.log("Permissions", permissions);

    if (permissions.state === 'granted') {
        // Permissions already granted, open modal directly
        if(modal.classList.contains('hidden')) {
             modal.classList.remove('hidden');
         }else{
             modal.classList.add('hidden');
        }
    } else if (permissions.state === 'prompt' || permissions.state === 'denied') {
        // Request permissions
        const granted = await requestCameraPermissions();
        if (granted) {
            // Permissions granted, open modal
            if(modal.classList.contains('hidden')) {
                modal.classList.remove('hidden');
            }else{
                modal.classList.add('hidden');
            }
            enumerateCameras(); // Ensure the camera list is up-to-date
        } else {
            // Handle the case where permissions are denied
            alert("Camera access is required to select a camera.");
        }
    }
}

async function requestCameraPermissions() {
    try {
        // Request permissions by attempting to access the camera
        await navigator.mediaDevices.getUserMedia({ video: true });
        console.log("Camera access granted.");
        return true; // Permissions granted
    } catch (error) {
        console.error("Camera access denied:", error);
        return false; // Permissions denied
    }
}

enumerateCameras();

</script>