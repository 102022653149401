<template>
  <!-- <div>
		{{ stockTakeStore.stockTakeSheets[0] }}
	</div>
	<div>
		{{ stockTakeStore.products[0] }}
	</div> -->

  <div class="py-6 px-2 flex justify-center">
    <div class="bg-white rounded-xl shadow-lg w-full lg:max-w-2xl">
      <div class="p-6 flex flex-col">
        <h2 class="font-bold text-xl mb-2 text-left hidden" id="pageTitle">Stock Take Sheet Products</h2>
        
        <!-- Success toast message -->
        <div id="myToast"
          class="hidden fixed right-10 bottom-10 px-5 py-4 border-r-8 border-green-500 bg-white drop-shadow-lg">
          <p class="text-sm">
            <span class="mr-2 inline-block px-3 py-1 rounded-full bg-green-500 text-white font-extrabold">i</span>
            Successfully captured quantity.
          </p>
        </div>

        <!-- Error toast message -->
        <div id="noProducts" class="hidden">
          <h2 class="text-xl mb-5">No products found</h2>

          <button id="returnToProductsBtn"
            class="inline-flex w-full justify-center rounded-md bg-blue-600 p-4 mt-4 text-base font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            @click="returnToProducts()">
            Return to products
          </button>

          <button id="returnToScanBtn"
            class="inline-flex w-full justify-center rounded-md bg-orange-600 p-4 mt-4 text-base font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            @click="returnToScan()">
            Return to scan
          </button>
        </div>

        <template>
          <TransitionRoot as="template" :show="open">
            <Dialog as="div" class="relative z-10" @close="open = false">
              <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </TransitionChild>

              <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-start justify-center p-4 text-center sm:items-center">
                  <TransitionChild as="template" enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <DialogPanel
                      class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-lg">
                      <div class="p-4">
                        <div class="">
                          <div>
                            <div v-if="selectedProduct !== null" class="mt-2">
                              <div class="mt-2">
                                <label class="text-gray-900/70 font-normal">Barcode</label>
                                <input type="text" disabled :value="selectedProduct.product_barcode"
                                  class="rounded p-2 text-lg w-full border border-gray-400" />
                              </div>
                              <div class="mt-2">
                                <label class="text-gray-900/70 font-normal">Description</label>
                                <input type="text" disabled :value="selectedProduct.product_description"
                                  class="rounded p-2 text-lg w-full border border-gray-400" />
                              </div>
                              <div class="mt-2 flex flex-col justify-end">
                                <div class="flex flex-col items-end justify-end">
                                  <label class="text-gray-900/70 font-normal">QTY</label>
                                  <input type="text" autofocus inputmode="numeric" v-model="updatedProductQty"
                                  class="rounded p-2 text-xl w-32 border border-gray-400 block text-right" />
                                </div>
                              </div>

                              <div v-if="isFromScanner === true">
                                <!-- Keypad for quantity -->
                                <div class="mt-4 grid grid-cols-3 gap-2">
                                  <button
                                    class="inline-block px-4 py-4 text-2xl rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                                    @click="appendDigit('1')">1</button>
                                  <button
                                    class="inline-block px-4 py-4 text-2xl rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                                    @click="appendDigit('2')">2</button>
                                  <button
                                    class="inline-block px-4 py-4 text-2xl rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                                    @click="appendDigit('3')">3</button>
                                  <button
                                    class="inline-block px-4 py-4 text-2xl rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                                    @click="appendDigit('4')">4</button>
                                  <button
                                    class="inline-block px-4 py-4 text-2xl rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                                    @click="appendDigit('5')">5</button>
                                  <button
                                    class="inline-block px-4 py-4 text-2xl rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                                    @click="appendDigit('6')">6</button>
                                  <button
                                    class="inline-block px-4 py-4 text-2xl rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                                    @click="appendDigit('7')">7</button>
                                  <button
                                    class="inline-block px-4 py-4 text-2xl rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                                    @click="appendDigit('8')">8</button>
                                  <button
                                    class="inline-block px-4 py-4 text-2xl rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                                    @click="appendDigit('9')">9</button>
                                  <button
                                    class="inline-block px-4 py-4 text-2xl rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                                    @click="appendDigit('0')">0</button>
                                  <button
                                    class="col-span-2 inline-block px-4 py-4 text-2xl rounded-lg bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:border-red-700 focus:ring focus:ring-red-200"
                                    @click="resetQuantity">Reset</button>
                                </div>
                                <!-- Reset button -->
                              </div>
                            </div>
                            <div v-else class="mt-2">
                              <p>
                                There is no product data to display. Please try again.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button"
                          class="inline-flex w-full justify-center rounded-md bg-blue-900 px-4 py-2 text-xl text-white hover:bg-blue-900/80 sm:ml-3 sm:w-auto"
                          @click="updateQuantity()">
                          Save
                        </button>
                        <button type="button"
                          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-xl text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          @click="returnToProducts()">
                          Cancel
                        </button>
                      </div>
                    </DialogPanel>
                  </TransitionChild>
                </div>
              </div>
            </Dialog>
          </TransitionRoot>
        </template>

        <div v-if="loading === true" class="flex flex-col justify-center p-6">
          <img src="../assets/spinners/Fading-lines.gif" alt="Loading..." class="self-center w-24 p-2" />
          <p class="font-medium text-lg italic">
            Busy fetching details...just a moment please...
          </p>
        </div>

        <div v-if="products.length > 0 && loading === false" class="flex flex-col space-y-4 mt-4">
          <div class="flex items-center mb-2 space-x-2">
            <!-- Search and scan products in this section -->
            <input type="text" class="rounded px-4 py-2 text-lg w-full border border-gray-400"
              placeholder="Search products" v-model="searchTerm" id="searchProduct" @keyup.enter="handleEnter" />
            <!-- This turns on the camera to scan a barcode -->
            <button id="cameraBtn" class="bg-blue-900 rounded px-3 h-10 border" @click="toggleCamera()">
              <CameraIcon class="h-6 w-6 text-white" />
            </button>
          </div>

          <div id="canvas-container" class="flex items-center mb-2 relative">
            <video id="video" autoplay playsinline muted hidden></video>
            <canvas id="canvas" class="hidden w-screen h-full"></canvas>
            <pre id="result" class="hidden"></pre>
          </div>

          <!-- Search a product barcode in this section -->
          <div id="manualScan" class="hidden">
            <label class="font-medium text-left">Enter barcode manually:</label>
            <div class="flex items-center mb-2 space-x-2">
              <input type="text" class="rounded px-2 py-1 text-lg w-full border border-gray-400"
                placeholder="Search barcode" v-model="barcode" />
              <button id="searchBtn" class="bg-blue-500 rounded p-0.5 border">
                <MagnifyingGlassIcon class="h-8 w-8" @click="searchBarcode()" />
              </button>
            </div>
          </div>

          <!-- Display products that has been scanned and to be scanned -->
          <div class="flex flex-col text-left px-2" id="productsContainer">
            <h2 id="toScan" class="font-bold border-b border-gray-300">
              To Scan Products
            </h2>
            <ul>
              <li v-for="product in categorizedProducts.toScan" :key="product.id" class="border-b py-1 my-1 text-sm"
                @click="openQuantityModal(product)">
                {{ product.product_description }}
              </li>
            </ul>

            <h2 id="scanned" class="font-bold border-b border-gray-300 mt-8">
              Scanned Products
            </h2>
            <ul v-if="categorizedProducts.scanned.length > 0">
              <li v-for="product in categorizedProducts.scanned" :key="product.id"
                class="py-1 text-sm my-1 line-through" @click="openQuantityModal(product)">
                {{ product.product_description }}
              </li>
            </ul>
            <p v-else>No scanned products yet.</p>
          </div>

          <div class="flex justify-between space-x-2" id="btnContainer">
            <button id="backToSheetsBtn" @click="backToSheets()"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold p-4 rounded w-full md:w-2/4 mb-2">
              Back to Sheets
            </button>
            <button id="cancelScanBtn"
              class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-2 rounded w-full md:w-2/4 mb-2 hidden"
              @click="toggleCamera()">
              Cancel
            </button>
          </div>
        </div>
        <div v-if="products.length === 0 && loading === false"
          class="block self-center w-full md:w-3/4 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 mr-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
          No Products Found
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-5 right-5 fixed">
    <div class="flex flex-col items-center space-y-2">
      <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow-md">
        <!-- <BarsArrowUpIcon class="h-6 w-6" @click="toggleKeyboard()" /> -->
        <BarsArrowUpIcon class="h-6 w-6" @click="keyboardOpen = true" />
      </button>




      <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow-md">
        <ArrowUpIcon class="h-6 w-6" href="#btnContainer" @click="scrollToTop()" />
      </button>

      <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow-md">
        <ArrowDownIcon class="h-6 w-6" href="#btnContainer" @click="scrollToBottom()" />
      </button>
    </div>
  </div>

  <!-- <template>
    <Dialog :open="keyboardOpen" @close="toggleKeyboard">
    <DialogPanel>
      <p>
        Are you sure you want to deactivate your account? All of your data will be
        permanently removed. This action cannot be undone.
      </p>
    </DialogPanel>
  </Dialog>
  </template> -->


  <template>
    <TransitionRoot as="template" :show="keyboardOpen">
      <Dialog as="div" class="relative z-10" @close="keyboardOpen = false">

        <div class="fixed bottom-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center sm:items-center sm:p-0">
            <!-- <TransitionChild as="template" enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"> -->
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full">
                <div class="bg-gray-50 px-2 pb-4 pt-2">

                  <div class="flex justify-end pb-2">
                    <button type="button"
                        class="inline-flex justify-center rounded-lg bg-white px-3 py-2 text-md font-semibold text-blue-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        @click="toggleKeyboard()">
                        Close
                    </button>
                  </div>

                  <div class="grid grid-cols-10 gap-2">
                    <!-- First row -->
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200">1</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200">2</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200">3</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200">4</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200">5</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200">6</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200">7</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200">8</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200">9</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200">0</button>
                  </div>

                  <div class="grid grid-cols-10 gap-2">
                    <!-- Second row -->
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                      @click="appendLetter('Q')">Q</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                      @click="appendLetter('W')">W</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                      @click="appendLetter('E')">E</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                      @click="appendLetter('R')">R</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                      @click="appendLetter('T')">T</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                      @click="appendLetter('Y')">Y</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                      @click="appendLetter('U')">U</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                      @click="appendLetter('I')">I</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                      @click="appendLetter('O')">O</button>
                    <button
                      class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                      @click="appendLetter('P')">P</button>
                  </div>

                    <div class="grid grid-cols-9 gap-2">
                      <!-- Third row -->
                      <button
                        class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                        @click="appendLetter('A')">A</button>
                      <button
                        class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                        @click="appendLetter('S')">S</button>
                      <button
                        class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                        @click="appendLetter('D')">D</button>
                      <button
                        class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                        @click="appendLetter('F')">F</button>
                      <button
                        class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                        @click="appendLetter('G')">G</button>
                      <button
                        class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                        @click="appendLetter('H')">H</button>
                      <button
                        class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                        @click="appendLetter('J')">J</button>
                      <button
                        class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                        @click="appendLetter('K')">K</button>
                      <button
                        class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                        @click="appendLetter('L')">L</button>

                    </div>
                      <div class="grid grid-cols-7 gap-2">
                        <!-- Fourth row -->
                        <button
                          class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                          @click="appendLetter('Z')">Z</button>
                        <button
                          class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                          @click="appendLetter('X')">X</button>
                        <button
                          class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                          @click="appendLetter('C')">C</button>
                        <button
                          class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                          @click="appendLetter('V')">V</button>
                        <button
                          class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                          @click="appendLetter('B')">B</button>
                        <button
                          class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200" 
                          @click="appendLetter('N')">N</button>
                        <button
                          class="col-span-1 px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200" 
                          @click="appendLetter('M')">M</button>

                        <div class="flex justify-between gap-2">
                        <!-- Fifth row -->
                        <button
                          class=" px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                          @click="appendLetter(' ')">Space</button>
                        <button
                          class=" px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                          @click="deleteLetter">Backspace</button>
                        <button
                          class=" px-4 py-2 text-lg rounded-lg text-gray-700 bg-gray-200 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200" 
                          @click="resetSearchBox">Clear</button>
                          </div>
                        
                      </div>

                    </div>
              </DialogPanel>
            <!-- </TransitionChild> -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>


</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import * as zbarWasm from "@undecaf/zbar-wasm";

import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";

import { CameraIcon } from "@heroicons/vue/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import { ArrowDownIcon } from "@heroicons/vue/24/outline";
import { ArrowUpIcon } from "@heroicons/vue/24/outline";
import { BarsArrowUpIcon } from "@heroicons/vue/24/outline";
// import { MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
// import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
// import { BeakerIcon } from "@heroicons/vue/24/outline";

import { useStockTakeStore } from "@/stores/stockTakeStore";

// const canvas = ref(null);
// const ctx = ref(null);
const videoElement = ref(null);
const canvasElement = ref(null);
const resultElement = ref(null);

const stockTakeStore = useStockTakeStore();

const open = ref(false);
const router = useRouter();

const route = useRoute();
const loading = ref(false);
const products = ref(stockTakeStore.products); // Array of products for a specific stock take sheet
const selectedProduct = ref(null); // Selected product to show in quantity capture modal
const searchTerm = ref("");
const barcode = ref("");
const updatedProductQty = ref(''); // Updated product quantity captured by user

const isFromScanner = ref(false);

// Method to append a digit to the quantity input field
const appendDigit = (digit) => {
  updatedProductQty.value += digit;
};

// Method to reset the quantity input field
const resetQuantity = () => {
  updatedProductQty.value = '';
};


// Method to append a letter to the search term input field
const appendLetter = (letter) => {
  searchTerm.value += letter;
};

//Method to delete a letter from the search term input field
const deleteLetter = () => {
  searchTerm.value = searchTerm.value.slice(0, -1);
};

// Method to reset the search term input field
const resetSearchBox = () => {
  searchTerm.value = '';
};



onMounted(async () => {
	loading.value = true;
	const sheetId = route.params.sheetId;
	videoElement.value = document.getElementById("video");
	canvasElement.value = document.getElementById("canvas");
	resultElement.value = document.getElementById("result");

	console.log("Getting products for sheet", sheetId);
	if (sheetId) {
		try {
			// Get products for this sheet
			const filteredProductsBySheet = computed(() => {
				const sheetIdAsNumber = Number(sheetId);
				return stockTakeStore.products.filter(
					(product) => product.stock_take_sheet_id === sheetIdAsNumber
				);
			});

			products.value = filteredProductsBySheet.value;
		} catch (error) {
			console.log(error);
		}
	}

  document.getElementById("searchProduct").focus();

	loading.value = false;
});

const keyboardOpen = ref(false);
const toggleKeyboard = () => {
  console.log("Before toggle", keyboardOpen.value);

  keyboardOpen.value = !keyboardOpen.value;

  console.log("After toggle", keyboardOpen.value);
};



const categorizedProducts = computed(() => {
	//Filter products based on the search term
	const filteredProducts = products.value.filter((product) => {
		const searchTextLower = searchTerm.value.toLowerCase();
		const productDescriptionLower = product.product_description.toLowerCase();
		return productDescriptionLower.includes(searchTextLower);
	});

	//Group products into toScan and scanned
	const toScan = [];
	const scanned = [];
	filteredProducts.forEach((product) => {
		if (product.status === "DONE" && product.qty !== "Nothing") {
			scanned.push(product);
		} else {
			toScan.push(product);
		}
	});

	return { toScan, scanned };
});

function checkVariableBarcode(barcode) {
	const checkArray = ["28", "29", "02", "25", "26", "21", "27", "20", "27"];
	for (let check of checkArray) {
		if (barcode.substring(0, 2) === check) {
			return true;
		}
	}
	return false;
}

function returnVariableBarcode(barcode) {
	const fiveDigitArray = ["28", "29", "02", "25"];
	const fourDigitArray = ["26", "21", "27", "20"];

	for (let check of fiveDigitArray) {
		if (barcode.substring(0, 2) === check) {
			return 5;
		}
	}

	for (let check of fourDigitArray) {
		if (barcode.substring(0, 2) === check) {
			return 4;
		}
	}

	return false;
}
  
const handleEnter = () => {
	console.log("Enter pressed");
	console.log(searchTerm.value);

  isFromScanner.value = true;
  searchBarcode(searchTerm.value); // Pass `true` to indicate it's initiated from the scanner
};

const openQuantityModal = (product) => {
	selectedProduct.value = product;
	open.value = true;
};

const searchBarcode = (scannedBarcode) => {
	console.log(
		"Memory totalJSHeapSize " +
			window.performance.memory.totalJSHeapSize / 1024 / 1024 +
			"MB"
	);
	console.log(
		"Memory used " + window.performance.memory.usedJSHeapSize / 1024 / 1024 + "MB"
	);
	console.log(
		"Memory limit " + window.performance.memory.jsHeapSizeLimit / 1024 / 1024 + "MB"
	);

	if (scannedBarcode) {
		if (scannedBarcode.length > 12 && checkVariableBarcode(scannedBarcode)) {
			const variableBarcodeLength = returnVariableBarcode(scannedBarcode);

			// console.log("Variable length", variableBarcodeLength);
			if (variableBarcodeLength) {
				scannedBarcode = scannedBarcode.substring(2, 2 + variableBarcodeLength);
			}
		}

		scannedBarcode = Number(scannedBarcode);

		scannedBarcode = scannedBarcode.toString();

		const product = products.value.find(
			(product) => product.product_barcode == Number(scannedBarcode)
		);

		if (product) {
			openQuantityModal(product);
		} else {
			document.getElementById("canvas").classList.add("hidden");
			document.getElementById("video").setAttribute("hidden", true);
			document.getElementById("noProducts").classList.remove("hidden");

			document.getElementById("returnToProductsBtn").classList.remove("hidden");
			document.getElementById("returnToScanBtn").classList.remove("hidden");

			document.getElementById("cancelScanBtn").classList.add("hidden");

			console.log("Product not found");
		}
	} else {
		console.log("No barcode to scan");
	}
};

const updateQuantity = async () => {
	if (!updatedProductQty.value) {
		console.log("No quantity entered");
		return;
	}

	if (!selectedProduct.value) {
		console.log("No product selected");
		return;
	}

	try {
		stockTakeStore.updateStockTakeProduct(
			selectedProduct.value.id,
			"DONE",
			updatedProductQty.value
		);
	} catch (error) {
		console.log("Error updating product:", error);
	} finally {
    barcode.value = "";
		selectedProduct.value = null; //reset selected product
		updatedProductQty.value = ""; //reset updated quantity modal value
    searchTerm.value = ""; 
		open.value = false; //close quantity capture modal

    console.log("value of isFromScanner:", isFromScanner.value);

    document.getElementById("productsContainer").classList.remove("hidden");
    document.getElementById("cameraBtn").classList.remove("hidden");
    document.getElementById("searchProduct").classList.remove("hidden");
    document.getElementById("video").setAttribute("hidden", true);
    document.getElementById("canvas").classList.add("hidden");
    document.getElementById("cancelScanBtn").classList.add("hidden");

    if (!isFromScanner.value) {
      // toggleCamera();
    }
		
    document.getElementById("searchProduct").focus();
		showSuccessToast();
	}
};

const showSuccessToast = () => {
	document.getElementById("myToast").classList.remove("hidden");
	// Hide the toast after 5 seconds (5000ms)
	// you can set a shorter/longer time by replacing "5000" with another number
	setTimeout(function () {
		document.getElementById("myToast").classList.add("hidden");
	}, 5000);
};

async function toggleCamera() {
	if (document.getElementById("canvas").classList.contains("hidden")) {
		document.getElementById("productsContainer").classList.add("hidden");
		document.getElementById("cameraBtn").classList.add("hidden");
		document.getElementById("searchProduct").classList.add("hidden");
		document.getElementById("canvas").classList.remove("hidden");
		document.getElementById("video").removeAttribute("hidden");
		document.getElementById("cancelScanBtn").classList.remove("hidden");

		let video;

		//   console.log("Value of cameraSelected in store:", stockTakeStore.selectedCamera);

		if (
			stockTakeStore.selectedCamera === "" ||
			stockTakeStore.selectedCamera === undefined
		) {
			// console.log("No camera selected");
			video = { facingMode: "environment" };
		} else {
			video = { deviceId: { exact: stockTakeStore.selectedCamera } };
			// console.log("Camera selected:", stockTakeStore.selectedCamera);
		}

		// console.log("Video", video);

		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({
					video,
				});
				videoElement.value.srcObject = stream;
				videoElement.value.play();
				canvasElement.value.style.display = "";
				// scanBarcode();
				startScanning();
			} catch (error) {
				console.error("Error accessing the camera", error);
			}
		}
	} else {
		closeVideoDisplay();
	}
}

function returnToProducts() {
  searchTerm.value = ""; 
	open.value = false;
	closeVideoDisplay();
}

function closeVideoDisplay() {
	document.getElementById("noProducts").classList.add("hidden");
	document.getElementById("productsContainer").classList.remove("hidden");
	document.getElementById("cameraBtn").classList.remove("hidden");
	document.getElementById("searchProduct").classList.remove("hidden");
	document.getElementById("canvas").classList.add("hidden");
	document.getElementById("cancelScanBtn").classList.add("hidden");
	document.getElementById("video").setAttribute("hidden", true);
	stopVideo();
}

function returnToScan() {
	document.getElementById("noProducts").classList.add("hidden");
	document.getElementById("returnToProductsBtn").classList.add("hidden");
	document.getElementById("returnToScanBtn").classList.add("hidden");
	toggleCamera();
}

let scanIntervalId = null;

function startScanning() {
	// Check if an interval has already been set
	if (scanIntervalId !== null) return;

	scanIntervalId = setInterval(async () => {
		// Ensure video element is available and ready
		if (
			!videoElement.value ||
			videoElement.value.videoWidth === 0 ||
			videoElement.value.videoHeight === 0
		) {
			console.log("Video is not ready for scanning.");
			return; // Skip this interval, wait for the next
		}

		let canvas;
		let context;

		// Try to use OffscreenCanvas if supported
		let offscreenCanvasSupported = false;
		try {
			offscreenCanvasSupported = Boolean(new OffscreenCanvas(1, 1).getContext("2d"));
		} catch (e) {
			console.error("OffscreenCanvas not supported:", e);
		}

		if (offscreenCanvasSupported) {
			canvas = new OffscreenCanvas(
				videoElement.value.videoWidth,
				videoElement.value.videoHeight
			);
			context = canvas.getContext("2d");
		} else {
			// Fallback to onscreen canvas if OffscreenCanvas is not supported
			// Make sure canvasElement is defined and points to a <canvas> element in your DOM
			canvas = canvasElement.value;
			canvas.width = videoElement.value.videoWidth;
			canvas.height = videoElement.value.videoHeight;
			context = canvas.getContext("2d");
		}

		// Draw the current frame from the video onto the canvas
		context.drawImage(videoElement.value, 0, 0, canvas.width, canvas.height);

		// Get the image data from the canvas
		const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

		try {
			const symbols = await zbarWasm.scanImageData(imageData);
			symbols.forEach((s) => (s.rawData = s.decode()));

			if (symbols.length > 0) {
				stopVideo(); // Implement this to stop the video or clear the interval
				searchBarcode(symbols[0].rawData); // Handle the scanned barcode as needed
				// Consider stopping the interval here if continuous scanning is not needed
				// stopScanning();
			}
		} catch (error) {
			console.error("Error scanning barcode:", error);
		}
	}, 1000); // Execute every 1000 milliseconds (1 second)
}

function stopScanning() {
	if (scanIntervalId !== null) {
		clearInterval(scanIntervalId);
		scanIntervalId = null;
	}
}

function stopVideo() {
	// Get the video element
	const videoElement = document.getElementById("video");
	// Check if the video element has a srcObject defined
	if (videoElement && videoElement.srcObject) {
		// Get all tracks from the srcObject
		const tracks = videoElement.srcObject.getTracks();
		// Iterate over each track and stop it
		tracks.forEach((track) => track.stop());
		// Clear the srcObject to null, releasing the media stream
		videoElement.srcObject = null;
	}

	stopScanning();
}

function backToSheets() {
	stopVideo();

	router.push("/");
}

function scrollToBottom() {
	document.getElementById("btnContainer").scrollIntoView({ behavior: "smooth" });
}

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

</script>



<style scoped></style>
